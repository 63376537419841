import { graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React from 'react'
import styled from 'styled-components'
import Button from '../components/Button'
import Divider from '../components/Divider'
import FlairText from '../components/FlairText'
import Heading from '../components/Heading'
import Layout from '../components/Layout'
import PagePadding from '../components/PagePadding'
import Paragraph from '../components/Paragraph'
import RichText from '../components/RichText'
import Seo from '../components/Seo'
import SerifText from '../components/SerifText'
import LinkConfig from '../types/LinkConfig'
import ServiceType from '../types/ServiceType'
import { getLinkConfig } from '../utils'

type Props = {
  data: {
    heroImage: {
      gatsbyImageData: any
    }
  }
  pageContext: {
    service: ServiceType
  }
}

function ServicePage({ data, pageContext }: Props) {
  const {
    service: {
      name,
      metaDescription,
      headline,
      heroImage,
      _rawDescription,
      pricedOfferings = [],
      _rawDisclaimers,
      callToAction,
    },
  } = pageContext

  let callToActionLink: LinkConfig
  if (callToAction) {
    callToActionLink = getLinkConfig(callToAction.link.url)
  }

  return (
    <Layout pageTitle={name}>
      <Seo title={name} description={metaDescription} />
      <Content>
        <Headline>{headline}</Headline>
        <HeroImage
          image={data.heroImage.gatsbyImageData}
          alt={heroImage ? heroImage.alt : ''}
        />
        <RichText value={_rawDescription} />
        {/* TODO: Make changeable with Sanity */}
        <HeadingWithLayout>Investment</HeadingWithLayout>
        <ul>
          {pricedOfferings.map(pricedService => {
            const {
              name: pricedServiceName,
              pricingInfo,
              description: pricedServiceDescription,
            } = pricedService
            return (
              <PricedService key={pricedServiceName}>
                <PricedServiceName as="h3">
                  {pricedServiceName}
                </PricedServiceName>
                <PricedServiceInfo>{pricingInfo}</PricedServiceInfo>
                {pricedServiceDescription && (
                  <PricedServiceDescription>
                    {pricedServiceDescription}
                  </PricedServiceDescription>
                )}
              </PricedService>
            )
          })}
        </ul>
        <Section>
          <RichText value={_rawDisclaimers} />
        </Section>
        <Divider />
        {callToAction && (
          <Section>
            {callToAction?.text && <Paragraph>{callToAction.text}</Paragraph>}
            {callToActionLink && (
              <ButtonLink
                as={callToActionLink.component}
                to={
                  callToActionLink.isInternal ? callToActionLink.url : undefined
                }
                href={
                  callToActionLink.isExternal ? callToActionLink.url : undefined
                }
              >
                {callToAction.link.displayText}
              </ButtonLink>
            )}
          </Section>
        )}
      </Content>
    </Layout>
  )
}

export default ServicePage

export const query = graphql`
  query($heroImageAssetId: String) {
    heroImage: sanityImageAsset(assetId: { eq: $heroImageAssetId }) {
      gatsbyImageData(layout: CONSTRAINED, placeholder: NONE, width: 650)
    }
  }
`

const Headline = styled(SerifText)`
  color: ${props => props.theme.colors.highlight};
  font-size: 2.125rem;
`

const HeadingWithLayout = styled(Heading)`
  font-size: 1.5rem;
  font-weight: 400;
`

const Content = styled(PagePadding)`
  font-size: 0.875rem;
  padding-top: 4rem;
  padding-bottom: 2rem;
  text-align: center;
`

const HeroImage = styled(GatsbyImage)<{ image: any }>`
  width: 650px;
  max-width: 100%;
  margin: 3rem auto;
`

const PricedService = styled.li`
  margin: 0 0 2rem 0;
`

const PricedServiceName = styled(FlairText)`
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
`

const PricedServiceInfo = styled(Paragraph)`
  margin: 0.5rem 0;
`

const PricedServiceDescription = styled(Paragraph)`
  font-style: italic;
  margin: 0.5rem 0;
`

const Section = styled.section`
  margin: 2rem auto;
  max-width: 750px;
`

const ButtonLink = styled(Button)`
  margin-top: 1rem;
  display: inline-block;
`
